import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    ArrayInput,
    Edit,
    FormTab,
    ImageField,
    ImageInput,
    SimpleFormIterator,
    TabbedForm,
} from 'react-admin'

export const ParentsEdit = (props: any) => {
    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="Section1">
                    <RichTextInput source="section1.title" />
                    <RichTextInput source="section1.subtitle" />
                    <RichTextInput source="section1.content" />
                </FormTab>
                <FormTab label="Section2">
                    <ArrayInput source="section2.cards">
                        <SimpleFormIterator>
                            <RichTextInput source="title" />
                            <RichTextInput source="content" />
                            <RichTextInput source="link" />
                            <ImageInput
                                {...props}
                                accept="image/*"
                                source="image"
                                multiple={false}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Section3">
                    <ArrayInput source="section3.gallery">
                        <SimpleFormIterator>
                            <RichTextInput source="date" />
                            <RichTextInput source="title" />
                            <RichTextInput source="content" />
                            <ImageInput
                                {...props}
                                accept="image/*"
                                source="image"
                                multiple={false}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}
