import RichTextInput from "ra-input-rich-text";
import { ArrayInput, Edit, EditProps, FormTab, NumberInput, SimpleFormIterator, TabbedForm, TextInput } from "react-admin";

const NewsEdit=(props:EditProps)=>{
    return(
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="Section1">
                    <RichTextInput source="section1.title" label="Title" />
                    <RichTextInput source="section1.subTitle" label="Sub Title" />
                    <RichTextInput source="section1.content" label="Content" />
                    <TextInput source="section1.link" label="Button Link" />
                </FormTab>
                <FormTab label="Section2">
                    <ArrayInput source='section2.news'>
                        <SimpleFormIterator>
                            <RichTextInput source="title" label="Title" />
                            <RichTextInput source="content" label="Content" />
                            <NumberInput source="day" label="Day" />
                            <TextInput source='month' label='Month' />
                            <TextInput source='year' label='Year' />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}
export default NewsEdit;