import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    Show,
    TextField,
    SimpleShowLayout,
    TabbedShowLayout,
    Tab,
    ArrayField,
    ImageField,
    Datagrid,
    ListProps,
} from 'react-admin'

export default function StudentsList(props: ListProps) {
    return (
        <Show {...props} title={'Students List View'}>
            <TabbedShowLayout>
                <Tab label="Section1">
                    <TextField source="section1.title" label={"Title"} />
                    <TextField source="section1.subtitle" label={"Subtitle"}/>
                    <TextField source="section1.content" label={"Content"}/>
                </Tab>
                <Tab label="Section2">
                    <ArrayField source="section2.cards" label={""}>
                        <Datagrid>
                            <TextField source="title" label={"Title"} />
                            <TextField source="content" label={"Content"}/>
                            <TextField source="link" label={"Link"}/>
                            <ImageField source="image.src" title="title" label={"Image"}/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Section3">
                    <ArrayField source="section3.gallery" label={""}>
                        <Datagrid>
                            <TextField source="date" />
                            <TextField source="title" />
                            <TextField source="content" />
                            <ImageField source="image.src" title="title" label={"Image"} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}
