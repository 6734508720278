import {
    Show,
    TextField,TabbedShowLayout,
    Tab,
    ArrayField,
    Datagrid,
    ListProps,
} from 'react-admin'

const NewsList=(props: ListProps)=>{
    return(
        <Show {...props} title={'News List View'}>
            <TabbedShowLayout>
                <Tab label="Section1">
                    <TextField source="section1.title" label="Title" />
                    <TextField source="section1.subTitle" label="Sub Title" />
                    <TextField source="section1.content" label="Content" />
                    <TextField source="section1.link" label="Button Link" />
                </Tab>
                <Tab label="Section2">
                    <ArrayField source='section2.news'>
                        <Datagrid>
                            <TextField source="title" label="Title" />
                            <TextField source="content" label="Content" />
                            <TextField source="day" label="Day" />
                            <TextField source='month' label='Month' />
                            <TextField source='year' label='Year' />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}
export default NewsList;