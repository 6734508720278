import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    ArrayField,
    ImageField,
    Datagrid,
    ListProps,
} from 'react-admin'

const EssayList = (props: ListProps) => {
    return (
        <Show {...props} title={'Essay List View'}>
            <TabbedShowLayout>
                <Tab label="Section1">
                    <TextField source="section1.title" label="Title" />
                    <TextField source="section1.content" label="Content" />
                </Tab>
                <Tab label="Section2">
                    <ArrayField source="section2.topics" label="Topics">
                        <Datagrid>
                            <TextField source="" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Section3">
                    <ArrayField source="section3.cards" label="Judging Proces">
                        <Datagrid>
                            <TextField source="title" label="Title" />
                            <TextField source="content" label="Content" />
                            <TextField source="link" label="Link" />
                            <ImageField source="image.src" title="image.title" label={'Image'}/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Section4">
                    <TextField source="content" label="Content" />
                </Tab>
                <Tab label="Section5">
                    <ArrayField source="section5.rules" label="Rules">
                        <Datagrid>
                            <TextField source="" />
                        </Datagrid>
                    </ArrayField>
                    <TextField
                        source="section5.registrationLink"
                        label="Registration Link"
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default EssayList
