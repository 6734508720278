import React from 'react'
import PropTypes from 'prop-types'
import {
    Show,
    TextField,
    SimpleShowLayout,
    TabbedShowLayout,
    Tab,
    ArrayField,
    ImageField,
    Datagrid,
    ListProps,
} from 'react-admin'
const HomeList = (props: ListProps) => {
    return (
        <Show {...props} title={'Home Page'}>
            <TabbedShowLayout >
                <Tab label="Section1">
                    <SimpleShowLayout>
                        <TextField source="section1.title1" label={"Title 1"}/>
                        <TextField source="section1.title2" label={"Title 2"}/>
                        <TextField source="section1.subtitle" label={"Subtitle"} />
                        <TextField source="section1.section_footer" label={"Footer"} />
                        <ArrayField source="section1.image" label={'Image(s)'}>
                            <Datagrid>
                                <ImageField source='src' label={''} />
                            </Datagrid>
                        </ArrayField>
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Section2">
                    <SimpleShowLayout>
                        <TextField source="section2.text" label={"Text"} />
                        <ImageField source='section2.hero_image.src' label={''} />
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Section3">
                    <SimpleShowLayout>
                        <ArrayField source='section3.cards' label={''}>
                            <Datagrid>
                                <TextField source="title" label={'Title'} />
                                <TextField source="heading" label={'Heading'} />
                                <TextField source="text" label={'Text'} />
                                <TextField source="url" label={'Url'} />
                            </Datagrid>
                        </ArrayField>
                    </SimpleShowLayout>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default HomeList
