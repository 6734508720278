import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    ArrayField,
    ImageField,
    Datagrid,
    ListProps,
} from 'react-admin'

const GalleryList = (props: ListProps) => {
    return (
        <Show {...props} title={'Gallery List View'} >
            <TabbedShowLayout>
                <Tab label="Section1">
                    <ArrayField source="section1.media" label="Media">
                        <Datagrid>
                            <TextField source="title" label="Title" />
                            <TextField source="content" label="Content" />
                            <TextField source="year" label="Year" />
                            <ImageField source="image.src" title="image.title" label={'Image'} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Section2">
                    <ArrayField source="section2.achievements" label="Acheivements" >
                        <Datagrid>
                            <ImageField source="src" title="title" />
                            <TextField source="year" label="Year" />
                            <TextField source="content" label="Content" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default GalleryList
