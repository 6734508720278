import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    ArrayField,
    ImageField,
    Datagrid,
    ListProps,
} from 'react-admin'

const AchievmentList = (props: ListProps) => {
    return (
        <Show {...props} title={'Achievements List View'}>
            <TabbedShowLayout>
                <Tab label="Section1">
                    <TextField source="section1.title" label="Title" />
                    <TextField source="section1.subTitle" label="Sub Title" />
                    <TextField source="section1.content" label="Content" />
                </Tab>
                <Tab label="Section2">
                    <ArrayField source="section2.winners" label="Winners">
                        <Datagrid>
                            <TextField source="title" label="Title" />
                            <TextField source="name" label="Name" />
                            <TextField source="description" label="Description" />
                            <TextField source="topic" label="Topic" />
                            <TextField source="link" label="Link" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Section3">
                    <ArrayField source="section3.pastWinners" label="Past Winners" >
                        <Datagrid>
                            <TextField source="year" label="Year" />
                            <TextField source="language" label="Language" />
                            <TextField source="state" label="State" />
                            <TextField source="name" label="Name" />
                            <TextField source="category" label="Category" />
                            <TextField source="school" label="School" />
                            <TextField source="city" label="City" />
                            <TextField source="link" label="Link" />
                            <ImageField source="image.src" title="image.title" label={''}/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default AchievmentList
