import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    Show,
    TextField,
    SimpleShowLayout,
    TabbedShowLayout,
    Tab,
    ArrayField,
    ImageField,
    Datagrid,
    ListProps,
} from 'react-admin'
const CommunityList = (props: ListProps) => {
    return (
        <Show {...props} title={'Community View'}>
            <TabbedShowLayout>
                <Tab label="Section1">
                    <TextField source="section1.title" label={'Title'}/>
                    <TextField source="section1.subtitle" label={'Subtitle'} />
                    <TextField source="section1.content" label={'Content'} />
                </Tab>
                <Tab label="Section2">
                    <ArrayField source="section2.cards" label={''}>
                        <Datagrid>
                            <TextField source="name" label={'Name'} />
                            <TextField source="designation" label={'Designation'} />
                            <TextField source="text" label={'Text'} />
                            <ImageField source="image.src" title="image.title" label={''} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Section3">
                    <ArrayField source="section3.videos" label={''}>
                        <Datagrid>
                            <TextField source="title" label={'Title'} />
                            <TextField source="url" label={'Url'} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default CommunityList
