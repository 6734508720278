import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    ArrayInput,
    Edit,
    EditProps,
    FormTab,
    ImageField,
    ImageInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
} from 'react-admin'

const GalleryEdit = (props: EditProps) => {
    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="Section1">
                    <ArrayInput source="section1.media" label="Media">
                        <SimpleFormIterator>
                            <RichTextInput source="title" label="Title" />
                            <RichTextInput source="content" label="Content" />
                            <RichTextInput source="year" label="Year" />

                            <ImageInput
                                {...props}
                                accept="image/*"
                                source="image"
                                multiple={false}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Section2">
                    <ArrayInput
                        source="section2.achievements"
                        label="Acheivements"
                    >
                        <SimpleFormIterator>
                            <ImageInput
                                {...props}
                                accept="image/*"
                                source="image"
                                multiple={false}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                            <TextInput source="year" label="Year" />
                            <TextInput source="content" label="Content" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default GalleryEdit
