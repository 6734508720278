import React from 'react'
import { Datagrid, List, ListProps, TextField } from 'react-admin'

const Contacts = (props: ListProps) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source="name" />
                <TextField source="email" />
                <TextField source="phone" />
                <TextField source="reason" />
                <TextField source="message" />
            </Datagrid>
        </List>
    )
}

export default Contacts
