import { Datagrid, List, ListProps, TextField } from 'react-admin'

const InviteSchool = (props: ListProps) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source="name" />
                <TextField source="email" />
                <TextField source="phone" />
                <TextField source="city" />
                <TextField source="state" />
            </Datagrid>
        </List>
    )
}
export default InviteSchool
