import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    ArrayInput,
    Edit,
    EditProps,
    FormTab,
    ImageField,
    ImageInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
} from 'react-admin'

const AchievmentEdit = (props: EditProps) => {
    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="Section1">
                    <RichTextInput source="section1.title" label="Title" />
                    <RichTextInput
                        source="section1.subTitle"
                        label="Sub Title"
                    />
                    <RichTextInput source="section1.content" label="Content" />
                </FormTab>
                <FormTab label="Section2">
                    <ArrayInput source="section2.winners" label="Winners">
                        <SimpleFormIterator>
                            <RichTextInput source="title" label="Title" />
                            <RichTextInput source="name" label="Name" />
                            <RichTextInput
                                source="description"
                                label="Description"
                            />
                            <RichTextInput source="topic" label="Topic" />
                            <TextInput source="link" label="Link" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Section3">
                    <ArrayInput
                        source="section3.pastWinners"
                        label="Past Winners"
                    >
                        <SimpleFormIterator>
                            <TextInput source="year" label="Year" />
                            <TextInput source="language" label="Language" />
                            <TextInput source="state" label="State" />
                            <RichTextInput source="name" label="Name" />
                            <RichTextInput source="category" label="Category" />
                            <RichTextInput source="school" label="School" />
                            <RichTextInput source="city" label="City" />
                            <TextInput source="link" label="Link" />
                            <ImageInput
                                {...props}
                                accept="image/*"
                                source="image"
                                multiple={false}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default AchievmentEdit
