import React from 'react'
import PropTypes from 'prop-types'
import {
    Edit,
    ArrayInput,
    TextInput,
    SimpleFormIterator,
    SimpleForm,
    ImageField,
    ImageInput,
    TabbedForm,
    FormTab,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
const HomeEdit = (props: any) => {
    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="Section1">
                    <RichTextInput source="section1.title1" />
                    <RichTextInput source="section1.title2" />
                    <RichTextInput source="section1.subtitle" />
                    <RichTextInput source="section1.section_footer" />
                    <ImageInput
                        {...props}
                        accept="image/*"
                        source="section1.image"
                        multiple={true}
                    >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Section2">
                    <RichTextInput source="section2.text" />
                    <ImageInput
                        {...props}
                        accept="image/*"
                        source="section2.hero_image"
                        multiple={false}
                    >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Section3">
                    <ArrayInput source="section3.cards">
                        <SimpleFormIterator>
                            <RichTextInput source="title" />
                            <RichTextInput source="heading" />
                            <RichTextInput source="text" />
                            <TextInput source="url" />
                            <ImageInput
                                {...props}
                                accept="image/*"
                                source="image"
                                multiple={false}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

// HomeEdit.propTypes = {}

export default HomeEdit
