import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    ArrayField,
    ImageField,
    Datagrid,
    ListProps,
} from 'react-admin'

export const ParentsList = (props: ListProps) => {
    return (
        <Show {...props} title={'Parents List View'} >
            <TabbedShowLayout>
                <Tab label="Section1">
                    <TextField source="section1.title" label={"Title"}/>
                    <TextField source="section1.subtitle" label={"Subtitle"} />
                    <TextField source="section1.content" label={"Content"} />
                </Tab>
                <Tab label="Section2">
                    <ArrayField source="section2.cards">
                        <Datagrid>
                            <TextField source="title" label={"Title"} />
                            <TextField source="content" label={"Content"} />
                            <TextField source="link" label={"Link"} />
                            <ImageField source="image.src" title="image.title" label={""}/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Section3">
                    <ArrayField source="section3.gallery">
                        <Datagrid>
                            <TextField source="date" label={"Date"}/>
                            <TextField source="title" label={"Title"} />
                            <TextField source="content" label={"Content"} />
                            <ImageField source="image.src" title="image.title" label={""} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}
