import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import Contacts from '../Components/Contacts'
import { Dashboard, DashboardEdit } from '../Components/Pages'
import dataProvider from '../Providers/dataProvider'
import authProvider from '../Providers/authProvider'
import { createTheme } from '@material-ui/core/styles'
import { usePermissions } from 'react-admin';

import MyLayout from '../Utils/Layout2'

import './App.css'
import InviteSchool from '../Components/Edit/invite-school'
import RegisterSchool from '../Components/Edit/register-school'
const theme = createTheme({
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
       },
})


const App = () => {
    const userName = localStorage.getItem('username');

    return (

        <Admin authProvider={authProvider} dataProvider={dataProvider} layout={MyLayout} theme={theme}>
            <Resource name="pages" list={Dashboard} edit= {DashboardEdit} />
            <Resource name="form/contact" list={Contacts} />
            <Resource name="form/invite" list={InviteSchool} />
            <Resource name="form/register" list={RegisterSchool} />
        </Admin>
    )
}

export default App
