import { DataProvider, fetchUtils } from 'react-admin'
import pages from './pages.json'
import { Buffer } from 'buffer'
const apiUrl =
    process.env.NODE_ENV === 'development'
    ? 'https://api.tbi.onpar.co.in'
    : 'https://api.tbi.onpar.co.in'
    //http://localhost:8000

const httpClient = (url: any, options: fetchUtils.Options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    // options.credentials = "include";
    return fetchUtils.fetchJson(url, options)
}
// let url = "";
const convertFileToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(file.rawFile)
    })
const dataProvider: DataProvider = {
    getList: async (resource: any, params: any) => {
        if (resource === 'pages') {
            return {
                data: pages,
                total: pages.length,
            }
        }
        if ((resource as string).includes('form')) {
            const { json } = await httpClient(`${apiUrl}/${resource}`, {
                method: 'GET',
            })
            json.forEach((item: any) => {
                item.id = item._id
            })
            return {
                data: json,
                total: json.length,
            }
        }
        return {
            data: [],
            total: 0,
        }
    },
    getMany: async (resource: any, params: any) => {},
    getOne: async (resource: any, params: any) => {
        const { json } = await httpClient(`${apiUrl}/page/${params.id}`)
        // json.id = json._id;

        // // return {data:json};
        const obj = {
            id: params.id,
            _id: json._id,
            section1: json.section1,
            section2: json.section2,
            section3: json.section3,
            section4: json.section4,
            section5: json.section5,
            section6: json.section6,
        }

        return { data: obj }
    },
    create: async (resource: any, params: any) => {},
    update: async (resource: any, params: any) => {
        delete params.data._id
        const keys: string[] = []
        const keyMap = new Map<string, string>()
        for await (const k of Object.keys(params.data)) {
            if (params.data[k] === null) {
                delete params.data[k]
                continue
            }
            for await (const k1 of Object.keys(params.data[k])) {
                if (params.data[k][k1] === null) {
                    delete params.data[k][k1]
                    continue
                }
                if (params.data[k][k1].rawFile instanceof File) {
                    const base64 = await convertFileToBase64(params.data[k][k1])
                    const fileType = params.data[k][k1].title.split('.')[1]
                    const key = `${
                        params.id
                    }/${k}/${k1}/${Date.now()}.${fileType}`
                    keys.push(key)
                    keyMap.set(key, base64 as string)
                    params.data[k][
                        k1
                    ].src = `https://tata-tbi-admin.s3.ap-south-1.amazonaws.com/${key}`
                }
                if (params.data[k][k1] instanceof Array) {
                    var i = 0
                    for await (const k2 of params.data[k][k1]) {
                        if (k2.rawFile instanceof File) {
                            const base64 = await convertFileToBase64(k2)
                            const fileType = k2.title.split('.')[1]
                            const key = `${
                                params.id
                            }/${k}/${k1}/${i}/${Date.now()}.${fileType}`
                            i++
                            keys.push(key)
                            keyMap.set(key, base64 as string)
                            k2.src = `https://tata-tbi-admin.s3.ap-south-1.amazonaws.com/${key}`
                        }
                        if (k2 instanceof Object) {
                            for await (const k3 of Object.keys(k2)) {
                                if (k2[k3].rawFile instanceof File) {
                                    const base64 = await convertFileToBase64(
                                        k2[k3]
                                    )
                                    const fileType = k2[k3].title.split('.')[1]
                                    const key = `${
                                        params.id
                                    }/${k}/${k1}/${k3}/${i}/${Date.now()}.${fileType}`
                                    i++
                                    keys.push(key)
                                    keyMap.set(key, base64 as string)
                                    k2[
                                        k3
                                    ].src = `https://tata-tbi-admin.s3.ap-south-1.amazonaws.com/${key}`
                                }
                            }
                        }
                    }
                }
            }
        }
        const data = await httpClient(`${apiUrl}/aws/getsignedurl`, {
            method: 'POST',
            body: JSON.stringify({ keys: keys }),
        })
        const signedUrls = data.json
        for await (const key of keyMap.keys()) {
            const signedUrl = signedUrls[key]
            const fileType = key.split('.')[1]
            const d = await httpClient(signedUrl, {
                method: 'PUT',
                body: Buffer.from(
                    (keyMap.get(key) as string).split(';base64,')[1],
                    'base64'
                ),
                headers: new Headers({
                    'content-type': `image/${fileType}`,
                }),
            })
        }
        const { json } = await httpClient(`${apiUrl}/page/${params.id}`, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
        })
        json.id = json._id
        return { data: json }
    },
    delete: async (resource: any, params: any) => {
        console.log(resource)
        console.log(params)
        
    },
} as unknown as DataProvider
export default dataProvider
