import React from 'react'
import {
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    ArrayField,
    ImageField,
    Datagrid,
    ListProps,
} from 'react-admin'

const SchoolsList = (props: ListProps) => {
    return (
        <Show {...props} title={'School List View'}>
            <TabbedShowLayout>
                <Tab label="Section1">
                    <TextField source="section1.title" label={'Title'}/>
                    <TextField source="section1.subtitle" label={'Subtitle'} />
                    <TextField source="section1.content" label={'Content'} />
                </Tab>
                <Tab label="Section2">
                    <ArrayField source="section2.cards" label={''}>
                        <Datagrid>
                            <TextField source="title" />
                            <TextField source="content" />
                            <TextField source="link" />
                            <ImageField source="image.src" label={'Image'} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default SchoolsList
