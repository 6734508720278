import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    ArrayInput,
    Edit,
    EditProps,
    FormTab,
    ImageField,
    ImageInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
} from 'react-admin'

const EssayEdit = (props: EditProps) => {
    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="Section1">
                    <RichTextInput source="section1.title" label="Title" />
                    <RichTextInput source="section1.content" label="Content" />
                </FormTab>
                <FormTab label="Section2">
                    <ArrayInput source="section2.topics" label="Topics">
                        <SimpleFormIterator>
                            <RichTextInput source="" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Section3">
                    <ArrayInput source="section3.cards" label="Judging Proces">
                        <SimpleFormIterator>
                            <RichTextInput source="title" label="Title" />
                            <RichTextInput source="content" label="Content" />
                            <TextInput source="link" label="Link" />
                            <ImageInput
                                {...props}
                                accept="image/*"
                                source="image"
                                multiple={false}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Section4">
                    <RichTextInput source="content" label="Content" />
                </FormTab>
                <FormTab label="Section5">
                    <ArrayInput source="section5.rules" label="Rules">
                        <SimpleFormIterator>
                            <RichTextInput source="" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <TextInput
                        source="section5.registrationLink"
                        label="Registration Link"
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default EssayEdit
